<template>
  <font-awesome-icon v-if="indicator === 1 | indicator === 4" class="icon-spacer-right text-danger" icon="xmark" />
  <font-awesome-icon
    v-else-if="indicator === 2"
    class="icon-spacer-right text-success"
    icon="check"
  />
  <font-awesome-icon v-else-if="indicator === 3" class="icon-spacer-right" icon="clock" />
</template>
<script>
export default {
  name: 'IndicatorIcon',
  props: {
    indicator: {
      type: Number,
      required: false,
      default: null,
    },
  },
};
</script>
