<template>
  <div id="sidebarMenu" class="bg-light shift" :class="{ expanded: !isCollapsed }">
    <nav class="nav-container">
      <div class="position-sticky w-100">
        <ul class="nav flex-column flex-nowrap">
          <li class="nav-item">
            <a class="nav-link fw-bolder" data-bs-toggle="collapse" href="#serviceSection" role="button" aria-expanded="true" aria-controls="serviceSection" @click="serviceSectionOpen = !serviceSectionOpen">
              <font-awesome-icon :icon="serviceSectionOpen ? 'caret-down' : 'caret-right'" class="icon" /> Service
            </a>
            <div class="collapse show" id="serviceSection">
              <ul class="nav flex-column">
                <li v-if="isSchemeViewer" class="nav-item">
                  <router-link to="/schemes" class="nav-link" :class="selectedSchemes"><font-awesome-icon icon="industry"
                      class="icon" /> Schemes</router-link>
                </li>
                <li v-if="isLicenceViewer" class="nav-item">
                  <router-link to="/businesses" class="nav-link" :class="selectedBusinesses"><font-awesome-icon icon="users"
                      class="icon" /> Businesses</router-link>
                </li>
                <li v-if="isLicenceViewer" class="nav-item">
                  <router-link to="/tmlns" class="nav-link" :class="selectedLicenceNumbers"><font-awesome-icon icon="passport"
                      class="icon" /> Licence Numbers</router-link>
                </li>
                <li v-if="isApplicationViewer || isTradingStandards" class="nav-item">
            <router-link to="/applications" class="nav-link" :class="selectedApplications"><font-awesome-icon
                      icon="rectangle-list" class="icon" /> Applications</router-link>
                </li>
                <li v-if="isLicenceEditor" class="nav-item">
                  <router-link to="/pending-licences" class="nav-link" :class="selectedPendingLicences"><font-awesome-icon
                      icon="down-left-and-up-right-to-center" class="icon" /> Pending
                    Licences
                    <span class="badge bg-danger ms-3">{{ badgePendingLicences }}</span></router-link>
                </li>
                <li v-if="isTradingStandardsEditor" class="nav-item">
                  <router-link to="/tradingstandards" class="nav-link"
                    :class="selectedTradingStandardsAdmin"><font-awesome-icon icon="circle-check" class="icon" /> Trading
                    Standards
                    <span v-if="badgeTSResponseReceived !== null" class="badge bg-danger ms-2">{{
                      badgeTSResponseReceived
                    }}</span>
                  </router-link>
                </li>
                <li v-if="isTradingStandsFeedbackUser" class="nav-item">
                  <router-link to="/trading-standards-feedback" class="nav-link"
                    :class="selectedTradingStandardsFeedback"><font-awesome-icon icon="rectangle-list" class="icon" /> Trading
                    Standards
                    Intel</router-link>
                </li>
                <li v-if="isDisputesViewer && (mode === 'development' || mode === 'uat')" class="nav-item">
                  <router-link to="/disputes" class="nav-link" :class="selectedDisputes"><font-awesome-icon icon="gavel"
                      class="icon" /> Disputes</router-link>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item">
            <a v-if="!isOnlyTradingStandardsViewer"
              class="nav-link fw-bolder" data-bs-toggle="collapse" href="#configurationSection" role="button" aria-expanded="false" aria-controls="configurationSection" @click="configurationSectionOpen = !configurationSectionOpen">
              <font-awesome-icon :icon="configurationSectionOpen ? 'caret-down' : 'caret-right'" class="icon" /> Configuration
            </a>
            <div class="collapse" id="configurationSection">
              <ul class="nav flex-column">
                <li v-if="isTradeViewer" class="nav-item">
                  <router-link to="/trades" class="nav-link" :class="selectedTrades"><font-awesome-icon icon="helmet-safety"
                      class="icon" /> Trades</router-link>
                </li>
                <li v-if="isFunderViewer" class="nav-item">
                  <router-link to="/funders" class="nav-link" :class="selectedFunders"><font-awesome-icon icon="piggy-bank"
                      class="icon" /> Funders</router-link>
                </li>
                <li v-if="isUserAdmin" class="nav-item">
                  <router-link to="/backoffice-users" class="nav-link" :class="selectedUsers"><font-awesome-icon
                      icon="address-card" class="icon" /> BackOffice Users</router-link>
                </li>
                <li v-if="isPropertyCheckerUserViewer" class="nav-item">
                  <router-link to="/property-checker/users" class="nav-link"
                    :class="selectedPropertyCheckerUsers"><font-awesome-icon icon="person" class="icon" /> Property Checker
                    Users</router-link>
                </li>

                <li v-if="isBlockedEmailListEditor" class="nav-item">
                  <router-link to="/blocked-emails" class="nav-link" :class="selectedBlockedEmails"><font-awesome-icon
                      icon="envelope" class="icon" /> Blocked Emails</router-link>
                </li>
                <li v-if="isSiteSettingsViewer" class="nav-item">
                  <router-link to="/site-settings" class="nav-link" :class="selectedSiteSettings"><font-awesome-icon
                      icon="gear" class="icon" /> Site Settings</router-link>
                </li>
                <li v-if="isQuestionSetEditor" class="nav-item">
                  <router-link to="/audit-questions" class="nav-link" :class="selectedAuditQuestions"><font-awesome-icon
                      icon="question" class="icon" /> Audit Questions</router-link>
                </li>
                <li v-if="!isOnlyTradingStandardsViewer" class="nav-item">
                  <router-link to="/matrix-types" class="nav-link" :class="selectedMatrixTypes"><font-awesome-icon
                      icon="bacteria" class="icon" /> Matrix Types</router-link>
                </li>
                <li v-if="!isOnlyTradingStandardsViewer" class="nav-item">
                  <router-link to="/measure-types" class="nav-link" :class="selectedMeasures"><font-awesome-icon
                      icon="bag-shopping" class="icon" /> Measures Types</router-link>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item">
            <a v-if="!isOnlyTradingStandardsViewer"
              class="nav-link fw-bolder" data-bs-toggle="collapse" href="#apiSection" role="button" aria-expanded="false" aria-controls="apiSection" @click="apiSectionOpen = !apiSectionOpen">
              <font-awesome-icon :icon="apiSectionOpen ? 'caret-down' : 'caret-right'" class="icon" /> API
            </a>
            <div class="collapse" id="apiSection">
              <ul class="nav flex-column">
                <li v-if="isBackOfficeAccountManager" class="nav-item">
                  <router-link to="/software-providers" class="nav-link" :class="selectedSoftwareProviders"><font-awesome-icon
                      icon="house-laptop" class="icon" /> Software
                    Providers</router-link>
                </li>
                <li v-if="isEnergySupplierViewer" class="nav-item">
                  <router-link to="/energy-suppliers" class="nav-link" :class="selectedEnergySuppliers"><font-awesome-icon
                      icon="bolt" class="icon" /> Energy Suppliers</router-link>
                </li>
                <li v-if="isGuaranteeProviderViewer" class="nav-item">
                  <router-link to="/guarantee-providers" class="nav-link" :class="selectedGuaranteeProviders"><font-awesome-icon icon="building-columns"
                      class="icon" /> Guarantee Providers</router-link>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item">
            <a v-if="!isOnlyTradingStandardsViewer"
              class="nav-link fw-bolder" data-bs-toggle="collapse" href="#reportingSection" role="button" aria-expanded="false" aria-controls="reportingSection" @click="reportingSectionOpen = !reportingSectionOpen">
              <font-awesome-icon :icon="reportingSectionOpen ? 'caret-down' : 'caret-right'" class="icon" /> Reporting
            </a>
            <div class="collapse" id="reportingSection">
              <ul class="nav flex-column">
                <li v-if="isBillingAdmin" class="nav-item">
                  <router-link to="/registrations" class="nav-link" :class="selectedRegistrations"><font-awesome-icon
                      icon="calendar-check" class="icon" /> Registration
                    Billing</router-link>
                </li>
                <li v-if="isUserAdmin" class="nav-item">
                  <router-link to="/scheme-usage" class="nav-link" :class="selectedSchemeUsage"><font-awesome-icon
                      icon="chart-bar" class="icon" /> Scheme Usage</router-link>
                </li>
                <li v-if="isInsightsViewer" class="nav-item">
                  <router-link to="/insights" class="nav-link" :class="selectedInsights"><font-awesome-icon icon="chart-pie"
                      class="icon" /> Insights</router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <hr />
        <h6 class="
            sidebar-heading
            d-flex
            justify-content-between
            align-items-center
            px-3
            mt-4
            mb-1
            text-muted
          ">
          <span>Quick Links</span>
        </h6>
        <ul class="nav flex-column mb-2">
          <li class="nav-item">
            <a class="nav-link" href="https://www.trustmark.org.uk/" target="_blank" rel="noopener noreferrer">
              TrustMark
            </a>
          </li>
        </ul>
        <hr />
        <ul class="nav flex-column mb-2">
          <li class="nav-item">
            <router-link to="/account" class="nav-link" :class="selectedAccount"><font-awesome-icon
                icon="user" class="icon" /> Account</router-link>
          </li>
        </ul>
        <small class="ms-3 pt-5">Build: {{ buildDate }}</small>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'BackOfficeSidebar',
  components: {},
  data() {
    return {
      mode: import.meta.env.VITE_MODE,
      buildDate: import.meta.env.VITE_BUILD_VERSION,
      serviceSectionOpen: true,
      configurationSectionOpen: false,
      reportingSectionOpen: false,
      apiSectionOpen: false,
    };
  },
  computed: {
    isCollapsed() {
      return this.$store.state.sidebarIsCollapsed;
    },
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isSiteSettingsViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('SiteSettingsViewer')
        : false;
    },
    isSchemeViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('SchemeViewer')
        : false;
    },
    isTradeViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('TradeViewer')
        : false;
    },
    isUserAdmin() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('BackOfficeUserViewer')
        : false;
    },
    isPropertyCheckerUserViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('PropertyCheckerUserViewer')
        : false;
    },
    isBillingAdmin() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('BillingManager')
        : false;
    },
    isApplicationViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('ApplicationViewer')
        : false;
    },
    isTradingStandardsEditor() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('ApplicationViewer')
        : false;
    },
    isLicenceViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('LicenceViewer')
        : false;
    },
    isFunderViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('FunderViewer')
        : false;
    },
    isGuaranteeProviderViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('GuaranteeProviderViewer')
        : false;
    },
    isLicenceEditor() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('LicenceEditor')
        : false;
    },
    isTradingStandards() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('TradingStandards')
        : false;
    },
    isBackOfficeAccountManager() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('BackOfficeAccountManager')
        : false;
    },
    isBlockedEmailListEditor() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('BlockedEmailList')
        : false;
    },
    isQuestionSetEditor() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('QuestionSetEditor')
        : false;
    },
    isTradingStandsFeedbackUser() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('TradingStandardsFeedback')
        : false;
    },
    isEnergySupplierViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('EnergySupplierViewer')
        : false;
    },
    isInsightsViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('Insights')
        : false;
    },
    isDisputesViewer() {
      return this.currentAccount && this.currentAccount.roles
        ? this.currentAccount.roles.includes('DisputesViewer')
        : false;
    },
    isOnlyTradingStandardsViewer() {
      return this.currentAccount
        && this.currentAccount.roles
        && this.currentAccount.roles.length === 2
        && this.currentAccount.roles.includes('BackOfficePortalAccess')
        && this.currentAccount.roles.includes('TradingStandardsFeedback');
    },
    selectedMeasures() {
      return this.$route.meta.measures ? 'selected' : '';
    },
    selectedEnergySuppliers() {
      return this.$route.meta.energySuppliers ? 'selected' : '';
    },
    selectedSchemes() {
      return this.$route.meta.schemes ? 'selected' : '';
    },
    selectedUsers() {
      return this.$route.meta.users ? 'selected' : '';
    },
    selectedRegistrations() {
      return this.$route.meta.registrations ? 'selected' : '';
    },
    selectedApplications() {
      return this.$route.meta.applications ? 'selected' : '';
    },
    selectedTradingStandardsAdmin() {
      return this.$route.meta.tradingStandardsadmin ? 'selected' : '';
    },
    selectedTrades() {
      return this.$route.meta.trades ? 'selected' : '';
    },
    selectedLicenceNumbers() {
      return this.$route.meta.tmlns ? 'selected' : '';
    },
    selectedBusinesses() {
      return this.$route.meta.business ? 'selected' : '';
    },
    selectedFunders() {
      return this.$route.meta.funders ? 'selected' : '';
    },
    selectedGuaranteeProviders() {
      return this.$route.meta.guaranteeProviders ? 'selected' : '';
    },
    selectedPropertyCheckerUsers() {
      return this.$route.meta.propertyCheckerUsers ? 'selected' : '';
    },
    selectedPendingLicences() {
      return this.$route.meta.pendingLicences ? 'selected' : '';
    },
    selectedSiteSettings() {
      return this.$route.meta.siteSettings ? 'selected' : '';
    },
    selectedBlockedEmails() {
      return this.$route.meta.blockedEmails ? 'selected' : '';
    },
    selectedAuditQuestions() {
      return this.$route.meta.auditQuestions ? 'selected' : '';
    },
    selectedSchemeUsage() {
      return this.$route.meta.schemeUsage ? 'selected' : '';
    },
    selectedSoftwareProviders() {
      return this.$route.meta.softwareProviders ? 'selected' : '';
    },
    selectedTradingStandardsFeedback() {
      return this.$route.meta.tradingStandardsfeedback ? 'selected' : '';
    },
    selectedInsights() {
      return this.$route.meta.insights ? 'selected' : '';
    },
    selectedMatrixTypes() {
      return this.$route.meta.matrixTypes ? 'selected' : '';
    },
    selectedAccount() {
      return this.$route.meta.account ? 'selected' : '';
    },
    selectedDisputes() {
      return this.$route.meta.disputes ? 'selected' : '';
    },
    badgePendingLicences() {
      return this.$store.state.pendingLicenceAction.badge;
    },
    badgeTSPending() {
      return this.$store.state.tradingStandardsAdmin.badges.pending;
    },
    badgeTSApplied() {
      return this.$store.state.tradingStandardsAdmin.badges.applied;
    },
    badgeTSAInvoicePaid() {
      return this.$store.state.tradingStandardsAdmin.badges.invoicePaid;
    },
    badgeTSResponseReceived() {
      return this.$store.state.tradingStandardsAdmin.badges.tradingStandardsResponseReceived;
    },
  },
  watch: {
    isCollapsed(collapsed) {
      (collapsed ? this.removeListeners : this.addListeners)();
    },
  },
  methods: {
    addListeners() {
      window.addEventListener('click', this.onClickOutside, { passive: true, capture: true });
    },
    removeListeners() {
      window.removeEventListener('click', this.onClickOutside, { passive: true, capture: true });
    },
    onClickOutside(event) {
      const el = this.$el;
      if (el !== event.target && !event.composedPath().includes(el)) {
        event.stopPropagation();
        this.$store.commit('setSidebarCollapsed', true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#sidebarMenu {
  background-color: #71217b;
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
  display: none;
  width: 100%;

  &.expanded {
    display: flex;
  }
}

.nav-container {
  padding: 10px 16px;
  box-sizing: border-box;
  width: 100%;
}

.nav-item:has(.show), .nav-item:has(.collapsing) {
  background-color: #dee2e6;
  border-radius: 5px;
}

.nav-item:has(.collapse), .nav-item:has(.collapsing) {
  margin-bottom: 5px;
}

@include media-breakpoint-up(lg) {
  #sidebarMenu {
    display: flex;
    width: 316px;
  }

  .nav-container {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
  }
}

.collapse, .collapsing {
  .nav {
    padding-left: 10px;
  }
}

.nav-link {
  font-size: 0.875em;
  font-weight: 600;
  color: #495057;
  white-space: nowrap;
  padding: 10px 10px;
}

.nav-item .icon {
  font-size: $font-size-base;
  margin-right: 2px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  padding: 0;
}

/* SHIFT */
.shift ul li {
  position: relative;
  z-index: 1;
}

.shift ul li a:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: '.';
  color: transparent;
  background: #dee2e6;
  visibility: none;
  opacity: 0;
  z-index: -1;
  border-radius: 5px;
}

.selected, .selected:hover {
  background: #71217b !important;
  border-radius: 5px;
  color: white !important;
}

.shift ul li a:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.shift ul li a:hover {
  color: #000;
  border-radius: 5px;
  background-color: #d0d3d6;
}

.nav-link:focus {
  color: #495057;
}
</style>
