import axios from 'axios';
import { HTTP, API_URL, authHeader } from './http-service';

class GuaranteeProviderService {
  register(payload) {
    return HTTP.post(`/GuaranteeProvider`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  get(id) {
    return HTTP.get(`/GuaranteeProvider/${id}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  getAll() {
    return HTTP.get(`/GuaranteeProvider`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  update(payload) {
    return HTTP.put(`/GuaranteeProvider`, payload, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  delete(id) {
    return HTTP.delete(`/GuaranteeProvider/${id}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  getAllCallLogs(id) {
    return HTTP.get(`/GuaranteeProvider/callLogs/${id}`, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  createGuaranteeProviderApiKey(request) {
    const http = axios.create({
      baseURL: API_URL,
      responseType: 'blob',
    });
    http.defaults.withCredentials = true;

    return http
      .post(`/guaranteeProvider/${request.guaranteeProviderId}/apikey/file`, request, {
        headers: authHeader(),
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        // Extract the filename from the response headers if available
        const contentDisposition = response.headers['Content-Disposition'];
        let fileName = 'apikey.csv';
        if (contentDisposition) {
          // Try to extract filename* first
          let [, filenameStar] = contentDisposition.match(/filename\*=(.+)$/) || [];
          filenameStar = filenameStar ? decodeURIComponent(filenameStar.split("''")[1]) : null;

          // Fallback to extracting filename
          const [, filename] = contentDisposition.match(/filename="([^"]*)"/) || [];

          // Use filename* if available, otherwise use filename
          fileName = filenameStar || filename || fileName;
        }
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
        return Promise.resolve(response);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
}

export default new GuaranteeProviderService();
