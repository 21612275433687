<template>
  <div class="card bg-light">
    <div class="card-header">
      Advanced Search
      <span class="float-end sticky-top" style="cursor: pointer" @click="close()">
        <font-awesome-icon icon="xmark" />
      </span>
      <div class="row">
        <div class="col-12">
          <smart-button
            class-override="btn-outline-secondary"
            class="mt-2"
            :disabled="false"
            :loading="loading"
            :click="exportApplications"
            >Export Results
          </smart-button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <smart-input
        id="modelApplicationReference"
        v-model="model.applicationReference"
        label="Application Reference"
        :is-required="false"
      />

      <smart-select-text-value-numeric
        id="modelSchemeId"
        v-model="model.applicationStatus"
        label="Status"
        :options="applicationStatuses"
      />
      <smart-select
        id="modelTradingStandardsWorkflowStatus"
        v-model="model.tradingStandardsWorkflowStatus"
        label="Trading Standards Workflow Status"
        :options="tradingStandardsWorkflowStatuses"
      />

      <smart-select
        id="model.ragRating"
        v-model="model.ragRating"
        label="RAG Rating"
        :options="ragRatings"
        :is-required="false"
      />

      <smart-input id="modelEmail" v-model="model.email" label="Email" :is-required="false" />

      <smart-input
        id="modelFirstName"
        v-model="model.firstName"
        label="First Name"
        :is-required="false"
      />

      <smart-input
        id="modelLastName"
        v-model="model.lastName"
        label="Last Name"
        :is-required="false"
      />

      <smart-input
        id="modelRegisteredCompanyName"
        v-model="model.registeredCompanyName"
        label="Registered Company Name"
        :is-required="false"
      />

      <smart-input
        id="modelRegisteredCompanyNumber"
        v-model="model.registeredCompanyNumber"
        label="Registered Company Number"
        :is-required="false"
      />

      <smart-input
        id="modelRegisteredAddressLine1"
        v-model="model.AddressLine1"
        label="Address Line 1"
        :is-required="false"
      />

      <smart-input
        id="modelResponsiblePersonAddressPostcode"
        v-model="model.responsiblePersonAddressPostcode"
        label="Responsible Person Postcode"
        :is-required="false"
      />

      <smart-select-key-value
        id="modelSchemeId"
        v-model="model.schemeId"
        label="Scheme"
        :is-required="false"
        :options="schemeIds"
      />

      <label class="form-label">From Date</label>
      <datepicker
        id="applicationFromDate"
        v-model="model.applicationFromDate"
        class="form-control"
        style="background: white"
        :input-format="inputFormat"
      />

      <label class="form-label mt-4">To Date</label>
      <datepicker
        id="applicationToDate"
        v-model="model.applicationToDate"
        class="form-control"
        style="background: white"
        :input-format="inputFormat"
      />

      <hr />

      <smart-select
        id="modelMatchType"
        v-model="model.matchType"
        label="Results must..."
        :options="matchTypes"
        :is-required="false"
      />

      <div class="row">
        <div class="col-6">
          <smart-button
            class-override="btn-outline-secondary"
            :disabled="false"
            :loading="false"
            :click="reset"
          >
            Reset
          </smart-button>
        </div>
        <div class="col-6">
          <smart-submit
            :small="true"
            :disabled="false"
            :loading="loading"
            :click="newAdvancedSearch"
            label="Apply"
          />
        </div>
      </div>
    </div>
  </div>
  <confirm-dialog ref="confirm"></confirm-dialog>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import {
  SmartInput,
  SmartSelect,
  SmartSubmit,
  SmartButton,
  SmartSelectKeyValue,
  ConfirmDialog,
  confirmType,
  SmartSelectTextValueNumeric,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import { constants } from '../../services/scheme-common';

export default {
  name: 'ApplicationsSearchAdvanced',
  components: {
    SmartButton,
    SmartSubmit,
    SmartInput,
    SmartSelect,
    ConfirmDialog,
    SmartSelectKeyValue,
    SmartSelectTextValueNumeric,
    Datepicker,
  },
  emits: ['reset-requested', 'error-message-changed', 'close'],
  data() {
    return {
      loading: false,
      model: {
        email: null,
        lastName: null,
        firstName: null,
        tradingStandardsWorkflowStatus: null,
        applicationStatus: null,
        ragRating: null,
        registeredCompanyNumber: null,
        registeredCompanyName: null,
        registeredAddressLine1: null,
        responsiblePersonAddressPostcode: null,
        applicationReference: null,
        schemeId: null,
        matchType: constants.matchTypes.exact,
      },
      params: [
        'email',
        'lastName',
        'firstName',
        'tradingStandardsWorkflowStatus',
        'applicationStatus',
        'registeredCompanyNumber',
        'registeredCompanyName',
        'registeredAddressLine1',
        'responsiblePersonAddressPostcode',
        'applicationReference',
      ],
      matchTypes: [constants.matchTypes.exact, constants.matchTypes.partial],
      ragRatings: [null, 'Red', 'Amber', 'Green'],
    };
  },
  computed: {
    pageNumber() {
      return this.$store.state.application.pageNumber;
    },
    lastSearchConfig() {
      return this.$store.state.application.lastSearchConfig;
    },
    isAdvancedSearch() {
      return this.lastSearchConfig.type === 'advanced';
    },
    applicationStatuses() {
      return this.$store.state.auth.appData.applicationStatuses.map((x) => {
        return { text: x.description, value: x.status };
      });
    },
    tradingStandardsWorkflowStatuses() {
      return this.$store.state.auth.appData.tradingStandardsWorkflowStatuses;
    },
    schemeIds() {
      const opts = this.$store.state.auth.appData.schemes.map((s) => {
        return { key: s.schemeId, value: s.schemeName };
      });
      opts.unshift({ key: null, value: '' });
      return opts;
    },
    inputFormat() {
      return 'dd/MM/yyyy';
    },
  },
  watch: {
    pageNumber() {
      if (this.isAdvancedSearch) {
        // console.log(`advanced pageNumber ${newPageNumber}`);
        this.advancedSearch();
      }
    },
  },
  methods: {
    newAdvancedSearch() {
      this.$store.commit('application/setSearchPageNumber', 0, { root: true });
      this.advancedSearch();
    },
    advancedSearch() {
      if (this.loading) return;
      this.$emit('error-message-changed', null);
      this.loading = true;

      const payload = {
        email: this.model.email,
        lastName: this.model.lastName,
        firstName: this.model.firstName,
        registeredCompanyNumber: this.model.registeredCompanyNumber,
        registeredCompanyName: this.model.registeredCompanyName,
        registeredAddressLine1: this.model.registeredAddressLine1,
        responsiblePersonAddressPostcode: this.model.responsiblePersonAddressPostcode,
        applicationReference: this.model.applicationReference,
        tradingStandardsRagRating: this.model.ragRating === '' ? null : this.model.ragRating,
        tradingStandardsWorkflowStatus: this.model.tradingStandardsWorkflowStatus,
        applicationStatus: this.model.applicationStatus,
        schemeId: this.model.schemeId,
        applicationFromDate: this.model.applicationFromDate,
        applicationToDate: this.model.applicationToDate,
        pageParameters: {
          size: 10,
          number: this.pageNumber,
        },
        sort: {
          item: 'applicationDate',
          direction: 'descending',
        },
        filterType: 0,
      };

      this.$store
        .dispatch('application/searchAdvanced', payload)
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$emit('error-message-changed', err.message);
        });
    },
    reset() {
      const that = this;

      this.params.forEach((p) => {
        that.model[p] = null;
      });
      this.model.status = null;
      this.$emit('reset-requested', null);
    },
    close() {
      this.$emit('close', null);
    },
    exportApplications() {
      if (this.loading) return;

        this.$refs.confirm
        .show({
            title: 'Export Results',
            message: `Are you sure you want to export the results?`,
            confirmType: confirmType.standard,
            okButtonText: 'Export Results',
          })
        .then((r) => {
          if (r) {
          this.loading = true;
          const payload = {
            email: this.model.email,
            lastName: this.model.lastName,
            firstName: this.model.firstName,
            registeredCompanyNumber: this.model.registeredCompanyNumber,
            registeredCompanyName: this.model.registeredCompanyName,
            registeredAddressLine1: this.model.registeredAddressLine1,
            responsiblePersonAddressPostcode: this.model.responsiblePersonAddressPostcode,
            applicationReference: this.model.applicationReference,
            tradingStandardsRagRating: this.model.ragRating === '' ? null : this.model.ragRating,
            tradingStandardsWorkflowStatus: this.model.tradingStandardsWorkflowStatus,
            applicationStatus: this.model.applicationStatus,
            schemeId: this.model.schemeId,
            applicationFromDate: this.model.applicationFromDate,
            applicationToDate: this.model.applicationToDate,
            pageParameters: {
              size: 100,
              number: 0,
            },
            sort: {
              item: 'registeredCompanyName',
              direction: 'ascending',
            },
            filterType: 0,
          };

          this.$store
            .dispatch('application/export', payload)
            .then((results) => {
              // Direct download of the file as csv
              const blob = new Blob([results], { type: 'text/csv' });
              const url = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `applications-${new Date().valueOf()}-export.csv`);
              link.setAttribute('target', '_blank');
              link.setAttribute('rel', 'noopener noreferrer');
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              this.$emit('error-message-changed', err.message);
            });
          }
      });
    },
  },
};
</script>
