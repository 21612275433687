<template>
  <tr v-if="schemeTrades && businessTrades && trade">
    <td :class="{ 'no-border-bottom': isPanelExpanded }">{{ tradeCode }}</td>
    <td :class="{ 'no-border-bottom': isPanelExpanded }">
      {{ trade.tradeDescription }}
    </td>
    <td :class="{ 'no-border-bottom': isPanelExpanded }">
      {{ trade.pasAnnex }}
    </td>
    <td :class="{ 'no-border-bottom': isPanelExpanded }">
      {{ trade.standard }}
    </td>
    <td :class="{ 'no-border-bottom': isPanelExpanded }">
      <div class="d-flex align-items-center">
        {{ trade.certificateId }}
        <div v-show="trade.isActive && updatingCertificateId" class="spinner-border text-primary col-sm-2"
          role="status">
          <span class="visually-hidden">Saving...</span>
        </div>
      </div>
      <span v-if="!trade.isActive">{{ trade.certificateId }}</span>
    </td>
    <td :class="{ 'no-border-bottom': isPanelExpanded }">
      <indicator-icon :indicator="overallIndicator"></indicator-icon>
      <span :class="{ ghost: overallIndicator === 0 }">
        {{ overallRegistrationStatusIndicator(overallIndicator) }}
      </span>
    </td>
    <td :class="{ 'no-border-bottom': isPanelExpanded }" class="text-right">
      <button class="btn btn-primary" @click="toggleEdit()">
        <span v-if="isPanelExpanded">
          <font-awesome-icon icon="chevron-down" />
        </span>
        <span v-else>
          <font-awesome-icon icon="chevron-up" />
        </span>
      </button>
    </td>
  </tr>
  <tr v-if="isPanelExpanded && schemeTrades && businessTrades">
    <td class="no-border-top" colspan="5">
      <div class="card bg-white mb-4">
        <div class="card-body">
          <div class="row">
            <!-- Business Trades List -->
            <div class="col-md-12 col-lg-6">
              <div>
                <h5 class="card-title mb-3">{{ trade.tradeDescription }} Registration Periods</h5>
              </div>

              <table v-if="trade.relatedBusinessTrades.length > 0" class="table bg-white mt-4">
                <thead>
                  <tr>
                    <th scope="col">Certificate Id</th>
                    <th scope="col">Status</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(rbt, i) in trade.relatedBusinessTrades" :key="i">
                    <td>
                      {{ rbt.certificateId }}
                    </td>
                    <td>
                      <indicator-icon :indicator="registrationStatus(rbt)"></indicator-icon>
                      {{ registrationStatusIndicator(rbt) }}
                    </td>
                    <td>
                      {{ $helpers.dateString(rbt.fromDate) }}
                    </td>
                    <td>
                      {{ $helpers.dateString(rbt.toDate, 'Ongoing') }}
                    </td>
                    <td>
                      <button v-if="isTradesEditor" class="btn btn-outline-primary float-right"
                        @click="showEditScreen(rbt)">
                        Change
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-else class="alert alert-info mt-4" role="alert">
                There are no registration periods for this trade.
              </div>

              <div v-if="successMessage" class="alert alert-success" role="alert">
                {{ successMessage }}
              </div>
            </div>

            <div class="col-md-12 col-lg-5 offset-lg-1">
              <button v-if="isTradesEditor && !isShowingAddScreen && !isShowingEditScreen"
                class="btn btn-outline-primary float-right" @click="showAddScreen">
                Add Registration Period
              </button>

              <!-- Add Registration Period -->
              <div v-if="isShowingAddScreen" class="card">
                <div class="col-12 card-body">
                  <h5 class="card-title mb-3">Add Registration Period</h5>
                  <hr />

                  <div class="row mb-3">
                    <div class="col-sm-12 col-md-6">
                      <label for="addFromDate" class="form-label">
                        <b> Certificate Id: </b>
                      </label>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <input v-model="addCertificateId" class="form-control" />
                    </div>
                    <div class="col-12">
                      <small>EG: Gas Safe / PAS / MCS certification number etc</small>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <div class="col-sm-12 col-md-6">
                      <label for="addFromDate" class="form-label">
                        <b> From Date: </b>
                      </label>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <datepicker id="addFromDate" v-model="addFrom" class="form-control" input-format="dd/MM/yyyy"
                        :clearable="false" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-check form-switch">
                        <input id="flexSwitchAddTradeActive" v-model="addTradeActive" class="form-check-input"
                          type="checkbox" checked @click="clearMessage" />
                        <span class="form-check-label" for="flexSwitchAddTradeActive">This trade is currently
                          Ongoing</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="!addTradeActive" class="row mb-3">
                    <div class="col-sm-12 col-md-6">
                      <label for="addToDate" class="form-label">
                        <b> To Date: </b>
                      </label>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <datepicker id="addToDate" v-model="addTo" class="form-control" input-format="dd/MM/yyyy"
                        :clearable="false" placeholder="Pick date" :lower-limit="addFrom" />
                    </div>
                  </div>

                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>

                  <button v-if="isShowingAddScreen || isShowingEditScreen" class="btn btn-outline-secondary float-right"
                    style="margin-left: 4px" @click="closeAddAndEditScreens">
                    Cancel
                  </button>
                  <button v-if="isShowingAddScreen" class="btn btn-outline-primary float-right" @click="saveAddition">
                    Save
                  </button>
                </div>
              </div>

              <!-- Change Registration Period -->
              <div v-if="isShowingEditScreen" class="card">
                <div class="col-12 card-body">
                  <h5 class="card-title mb-3">Change Registration Period</h5>
                  <hr />

                  <div class="row mb-3">
                    <div class="col-sm-12 col-md-6">
                      <label for="editFromDate" class="form-label">
                        <b> From Date: </b>
                      </label>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <datepicker id="editFromDate" v-model="editFrom" class="form-control" input-format="dd/MM/yyyy"
                        :clearable="false" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-check form-switch">
                        <input id="flexSwitchEditTradeActive" v-model="editTradeActive" class="form-check-input"
                          type="checkbox" checked @click="clearMessage" />
                        <span class="form-check-label" for="flexSwitchEditTradeActive">This trade is currently
                          Ongoing</span>
                      </div>
                    </div>
                  </div>

                  <div v-if="!editTradeActive" class="row mb-3">
                    <div class="col-sm-12 col-md-6">
                      <label for="editToDate" class="form-label">
                        <b> To Date: </b>
                      </label>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <datepicker id="editToDate" v-model="editTo" class="form-control" input-format="dd/MM/yyyy"
                        :clearable="false" placeholder="Pick date" :lower-limit="editFrom" />
                      <div class="col-sm-12 mt-1">
                        <button class="btn btn-outline-secondary" @click="setEditToToday">
                          Set End Today
                        </button>
                      </div>
                    </div>
                  </div>

                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>

                  <button v-if="isShowingAddScreen || isShowingEditScreen" class="btn btn-outline-secondary float-right"
                    style="margin-left: 4px" @click="closeAddAndEditScreens">
                    Cancel
                  </button>
                  <button v-if="isShowingEditScreen" class="btn btn-primary float-right" @click="saveEdition">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td class="no-border-top"></td>
  </tr>
</template>
<script>
import Datepicker from 'vue3-datepicker';
import {
  registrationStatus,
  registrationStatusIndicator,
  overallRegistrationStatus,
  overallRegistrationStatusIndicator,
} from '../../services/scheme-common';
import IndicatorIcon from './IndicatorIcon.vue';

export default {
  name: 'ExpandableTradeRow',
  components: {
    IndicatorIcon,
    Datepicker,
  },
  props: {
    tradeCode: {
      type: Number,
      required: false,
      default: null,
    },
    businessId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      successMessage: null,
      message: null,
      isPanelExpanded: false,
      addFrom: new Date(),
      addTo: null,
      addTradeActive: false,
      addCertificateId: null,
      editFrom: null,
      editTo: null,
      editTradeActive: false,
      isShowingAddScreen: false,
      isShowingEditScreen: false,
      tradeRegistrationIdToEdit: null,
      updatingCertificateId: false,
      trade: null,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.auth.account;
    },
    isTradesEditor() {
      return false;
    },
    schemeTrades() {
      if (!this.$store.state.trade.trades) null;
      return this.$store.state.trade.trades;
    },
    businessTrades() {
      if (!this.$store.state.schemeBusiness.business) return [];
      return this.$store.state.schemeBusiness.business.rawTrades;
    },
    overallIndicator() {
      return overallRegistrationStatus(this.trade);
    },
  },
  mounted() {
    this.setTrade();
  },
  methods: {
    overallRegistrationStatusIndicator,
    registrationStatus,
    registrationStatusIndicator,
    toggleEdit() {
      this.isPanelExpanded = !this.isPanelExpanded;
    },
    saveAddition() {
      this.successMessage = null;
      this.message = null;

      if (!this.addTradeActive && this.addTo === null) {
        this.onError(
          'You must either select this trade as Active or select a To Date to make it inactive.'
        );
        return;
      }

      if (this.addTo !== null && this.addTo < this.addFrom) {
        this.onError(
          'From Date must be the same or before To Date, or the Ongoing toggle must be on.'
        );
        return;
      }

      const fromDate = this.$helpers.dateForApi(this.addFrom);
      const toDate = this.addTradeActive ? null : this.$helpers.dateEndOfDayForApi(this.addTo);

      const payload = {
        schemeBusinessId: this.businessId,
        tradeCode: this.trade.tradeCode,
        fromDate,
        toDate,
        certificateId: this.addCertificateId,
      };

      this.$store.dispatch('businessTradeRegistration/createTradeRegistration', payload).then(
        () => this.onSuccess('Trade registration period saved.'),
        (error) => this.onError(error)
      );
    },
    saveEdition() {
      this.successMessage = null;
      this.message = null;

      if (!this.editTradeActive && this.editTo === null) {
        this.onError(
          'You must either select this trade as Active or select a To Date to make it inactive.'
        );
        return;
      }
      if (this.editTo !== null && this.editTo < this.editFrom) {
        this.onError(
          'From Date must be the same or before To Date, or the Ongoing toggle must be on.'
        );
        return;
      }

      const fromDate = this.$helpers.dateForApi(this.editFrom);
      const toDate = this.editTradeActive ? null : this.$helpers.dateEndOfDayForApi(this.editTo);

      const payload = {
        schemeBusinessId: this.businessId,
        tradeCode: this.trade.tradeCode,
        fromDate,
        toDate,
        tradeId: this.tradeRegistrationIdToEdit,
        certificateId: this.trade.certificateId,
      };

      this.$store.dispatch('businessTradeRegistration/updateTradeRegistration', payload).then(
        () => this.onSuccess('Trade registration period saved.'),
        (error) => this.onError(error)
      );
    },
    updateTradeCertificateId(value) {
      if (this.trade.relatedBusinessTrades.length === 0) return;

      this.successMessage = null;
      this.message = null;

      const now = new Date();
      now.setHours(2, 0, 0, 0);
      const trades = this.trade.relatedBusinessTrades.filter(
        (x) => new Date(x.fromDate) <= now && (x.toDate === null || new Date(x.toDate) > now)
      );
      if (trades.length > 0) {
        this.updatingCertificateId = true;
      }
      trades.forEach((t) => {
        const payload = {
          schemeBusinessId: this.businessId,
          tradeId: t.tradeId,
          tradeCode: t.tradeCode,
          fromDate: t.fromDate,
          toDate: t.toDate,
          certificateId: value,
        };
        this.$store.dispatch('businessTradeRegistration/updateTradeRegistration', payload).then(
          () => this.onSuccess('Certificate Id updated.'),
          (error) => this.onError(error)
        );
      });
    },
    onSuccess(msg) {
      this.isShowingEditScreen = false;
      this.isShowingAddScreen = false;
      this.successMessage = msg;
      setTimeout(() => {
        this.successMessage = '';
      }, 3000);
      this.updatingCertificateId = false;
    },
    onError(error) {
      this.message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      this.updatingCertificateId = false;
    },
    showAddScreen() {
      this.message = null;
      this.isShowingEditScreen = false;
      this.isShowingAddScreen = true;
      this.addFrom = new Date();
      this.addTo = null;
      this.addTradeActive = true;
      this.addCertificateId = this.trade.certificateId;
    },
    showEditScreen(rbt) {
      this.message = null;
      this.isShowingAddScreen = false;
      this.editFrom = new Date(rbt.fromDate);
      this.editTo = rbt.toDate === null ? null : new Date(rbt.toDate);
      this.tradeRegistrationIdToEdit = rbt.tradeId;
      this.editTradeActive = this.editTo === null;
      this.isShowingEditScreen = true;
    },
    closeAddAndEditScreens() {
      this.isShowingAddScreen = false;
      this.isShowingEditScreen = false;
      this.tradeRegistrationIdToEdit = null;
    },
    setEditToOngoing() {
      this.editTo = null;
    },
    setEditToToday() {
      const today = new Date();
      this.editTo = today;
    },
    clearMessage() {
      this.message = null;
    },
    setTrade() {
      const tc = this.tradeCode;
      const trade = this.schemeTrades.find((x) => x.tradeCode === tc);
      trade.relatedBusinessTrades = this.businessTrades.filter(
        (x) => x.tradeCode === trade.tradeCode
      );
      const now = new Date();
      trade.activeTrades = trade.relatedBusinessTrades.filter(
        (x) => now > new Date(x.fromDate) && (x.toDate === null || now < new Date(x.toDate))
      );
      trade.isActive = trade.activeTrades.length > 0;
      if (trade.activeTrades.length > 0) {

        const latest = trade.activeTrades[0];
        trade.certificateId = latest.certificateId;
        trade.expiry = latest.toDate;
        trade.from = latest.fromDate;

        if (new Date(trade.from) > now) {
          trade.status = 'Future';
        }
        if (trade.expiry && new Date(trade.expiry) < now) {
          trade.status = 'Expired';
        }
        if (trade.activeTrades && trade.activeTrades.filter(x => x.notAvailable).length > 0) {
          trade.status = 'Trade Expired';
        }
        else if (trade.expiry === null || new Date(trade.expiry) > now && new Date(trade.from) < now) {
          trade.status = 'Active';
        }
      } else {
        trade.status = '';
        trade.certificateId = '';
      }
      this.trade = trade;
    },
  },
};
</script>
