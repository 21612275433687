<template>
  <div>
    <div class="d-flex align-items-center">
      <h1>Edit <property-renderer :object="model" property="schemeName" /></h1>
      <div class="ms-3">
        <h5 v-if="isActiveStatus">
          <span class="badge bg-success">Active</span>
        </h5>
        <h5 v-if="!isActiveStatus">
          <span class="badge bg-danger">Inactive</span>
        </h5>
      </div>
    </div>
  </div>

  <div v-if="errorMessage" class="alert alert-danger" role="alert">
    {{ errorMessage }}
  </div>

  <div v-if="successMessage" class="alert alert-success" role="alert">
    {{ successMessage }}
  </div>

  <scheme-edit-tabs />
  <router-view :v="v$" />

  <div class="row">
    <div class="col-sm-12 col-md-10 col-lg-10">
      <div
        v-if="displayActionButtons"
        class="form-group d-grid gap-2 d-md-flex justify-content-md-end"
      >
        <router-link class="btn btn-secondary float-right btn-lg" type="button" to="/schemes">
          <span>Cancel</span>
        </router-link>

        <smart-submit-large label="Save" :loading="loading" @click="save" />
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import {
  SmartSubmitLarge,
  PropertyRenderer,
} from '@trustmark-2005-ltd/trustmark-shared-components';
import SchemeEditTabs from './SchemesEditTabs.vue';

export default {
  name: 'SchemesEditRouter',
  components: {
    SmartSubmitLarge,
    SchemeEditTabs,
    PropertyRenderer,
  },
  data() {
    return {
      schemeId: this.$route.params.schemeId,
      errorMessage: null,
      successMessage: null,
      v$: useValidate(),
      loading: false,
    };
  },
  computed: {
    model() {
      return this.$store.state.scheme.scheme;
    },
    displayActionButtons() {
      return !!this.$route.meta.displayActionButtons;
    },
    isActiveStatus() {
      return this.model?.status === 'Active';
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    if (!this.model || this.model.schemeId !== this.$route.params.schemeId) {
      this.$store.dispatch('scheme/getSchemeDetails', this.schemeId).then(() => {
        if (!this.model.benefits) {
          this.model.benefits = [{ description: '', orderNumber: 0 }];
        }
      });
    }
  },
  methods: {
    save() {
      this.errorMessage = null;
      this.v$.$validate();
      if (this.v$.$error) {
        window.scrollTo(0, 0);
        this.errorMessage =
          'Unable to save the scheme. Please resolve any errors on this form and try again.';
        const that = this;
        setTimeout(() => {
          that.errorMessage = null;
        }, 5000);
        return;
      }

      const payload = this.model;
      if (!this.model.benefits) {
        this.model.benefits = [];
      }

      if (this.model.dsa) {
        console.log(this.$store.state.auth.appData.dsaVersions)
        const version = this.$store.state.auth.appData.dsaVersions?.find(x => x.version.toString() === this.model.dsa);

        payload.dsaVersion = version.version;
        payload.dsaDescription = version.description;
      }

      this.model.benefits = this.model.benefits.filter((x) => x.description !== null);

      this.$store
        .dispatch('scheme/updateScheme', payload)
        .then((resp) => {
          window.scrollTo(0, 0);
          this.successMessage = resp;
          setTimeout(() => {
            this.successMessage = null;
          }, 5000);
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.errorMessage =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
        });
    },
  },
  validations: {
    model: {
      schemeName: {
        isRequired: helpers.withMessage('Scheme Name is required', required),
      },
      logoImage: {
        // isRequired: helpers.withMessage('Sitefinity Image Key is required', required),
      },
      hideFromJoin: {
        isRequired: helpers.withMessage('Hide From Join is required', required),
      },
      status: {
        isRequired: helpers.withMessage('Status is required', required),
      },
      dsa: {},
      benefits: {},
      allowRetrofitPortalInstallerAccess: {},
      allowRetrofitPortalRCRAAccess: {},
      useBillingDeclaration: {
        isRequired: helpers.withMessage('Use Billing Declaration is required', required),
      },
      membershipWebAddress: {
        isRequired: helpers.withMessage(
          'Membership Web Address is required when available for Application',
          (value, vm) => {
            if (vm.hideFromJoin) return true;
            return !vm.hideFromJoin && value;
          }
        ),
      },
      webAddress: {
        isRequired: helpers.withMessage(
          'Web Address is required when available for Application',
          (value, vm) => {
            if (vm.hideFromJoin) return true;
            return !vm.hideFromJoin && value;
          }
        ),
      },
      membershipInformation: {
        isRequired: helpers.withMessage(
          'Membership Information is required when available for Application',
          (value, vm) => {
            if (vm.hideFromJoin) return true;
            return !vm.hideFromJoin && value;
          }
        ),
      },
    },
  },
};
</script>
