<template>
  <div>
    <h1>View User</h1>

    <div class="mb-4">
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2">
          <button
            class="btn btn-outline-secondary"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            @click="showUserActivityLog"
          >
            View User Account Events
          </button>
        </div>
      </div>
    </div>

    <div v-if="model" class="h-100">
      <div :disabled="loading" class="pt-3">
        <div class="row">
          <div class="col-sm-12 col-md-10 col-lg-8">
            <card label="User Details">
              <div class="row">
                <div class="col">
                  <key-value-presenter
                    label="First Name"
                    :value="model.firstName"
                  ></key-value-presenter>
                </div>
                <div class="col">
                  <key-value-presenter
                    label="Last Name"
                    :value="model.lastName"
                  ></key-value-presenter>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <key-value-presenter label="Email" :value="model.accountId"></key-value-presenter>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>

    <div v-if="model && model.roles" class="row">
      <div class="col-sm-12 col-md-10 col-lg-8">
        <card label="Roles">
          <div class="mb-3">
            <span>This user can perform the following:</span>
          </div>

          <div v-if="!model.roles.length" class="alert alert-danger mt-3">
            Warning: This user will not be able to log in.
          </div>

          <ul>
            <li v-for="role in model.roles" :key="role">
              {{ role }}
            </li>
          </ul>
        </card>
      </div>
    </div>

    <div v-if="model" class="row">
      <div class="col-sm-12 col-md-10 col-lg-8">
        <div class="form-group d-grid gap-2 d-md-flex justify-content-md-end">
          <router-link class="btn btn-secondary float-right btn-lg" type="button" :to="parentRoute">
            <span>Back</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Card, KeyValuePresenter } from '@trustmark-2005-ltd/trustmark-shared-components';

export default {
  name: 'UserView',
  components: {
    KeyValuePresenter,
    Card,
  },
  data() {
    return {
      loading: false,
      rolesKeyValue: null,
    };
  },
  computed: {
    parentRoute() {
      return `/scheme/${this.schemeId}/edit/users`;
    },
    model() {
      return this.$store.state.schemeUser.user;
    },
    schemeId() {
      return this.$route.params.schemeId;
    },
    userId() {
      return this.$route.params.userid;
    },
  },
  mounted() {
    this.$store.dispatch('schemeUser/byEmail', { schemeId: this.schemeId, accountId: this.userId });
  },
  methods: {
    showUserActivityLog() {
      this.$router.push(`/scheme/${this.schemeId}/scheme-user/${this.userId}/events`);
    },
  },
};
</script>
