import GuaranteeProviderService from '../services/guarantee-provider-service';

export const guaranteeProvider = {
  namespaced: true,
  state: {
    guaranteeProvider: null,
    guaranteeProviders: [],
    guaranteeProviderCallLogs: [],
  },
  actions: {
    getCallLogs({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      return GuaranteeProviderService.getAllCallLogs(id).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setGuaranteeProviderCallLogs', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    registerGuaranteeProvider({ commit, dispatch }, data) {
      commit('setIsBusy', true, { root: true });
      return GuaranteeProviderService.register(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          setTimeout(() => {
            dispatch('executeLastSearch');
          }, 6000);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    getGuaranteeProvider({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      commit('setGuaranteeProvider', null);
      return GuaranteeProviderService.get(id).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setGuaranteeProvider', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    updateGuaranteeProvider({ commit }, data) {
      commit('setIsBusy', true, { root: true });
      return GuaranteeProviderService.update(data).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;
          return Promise.resolve(message);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    deleteGuaranteeProvider({ commit, dispatch }, payload) {
      commit('setIsBusy', true, { root: true });

      return GuaranteeProviderService.delete(payload.guaranteeProviderId).then(
        (result) => {
          commit('setIsBusy', false, { root: true });
          commit('setGuaranteeProvider', null);
          const { message } = result;
          return Promise.resolve(message);
        },
        (error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        }
      );
    },
    getAllGuaranteeProviders({ commit }) {
      return GuaranteeProviderService.getAll().then(
        (result) => {
          commit('setGuaranteeProviders', result.model);
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createGuaranteeProviderApiKey(_, request) {
      return GuaranteeProviderService.createGuaranteeProviderApiKey(request).then(
        (result) => {
          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    setGuaranteeProvider(state, newGuaranteeProvider) {
      state.guaranteeProvider = newGuaranteeProvider;
    },
    setGuaranteeProviders(state, newGuaranteeProviders) {
      state.guaranteeProviders = newGuaranteeProviders;
    },
    setGuaranteeProviderCallLogs(state, newGuaranteeProviderCallLogs) {
      state.guaranteeProviderCallLogs = newGuaranteeProviderCallLogs;
    },
  },
};
